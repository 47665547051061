
import request from "../../Utils/curl"
import moment from "moment"
import Utility from "../../Utils/utility"
let Organisations = {
  /**
   * organisationList
   */
  async organisationList (context, filter = {}, currentPage = "") {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."

    try {
      let postData = new FormData()
      if (currentPage) {
        postData.append("currentPage", currentPage)
      }

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }

      return await request.curl(context, "organisation_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationList() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * organisationListSearch
  */
  async organisationListSearch (context, filter = {}) {
    try {
      let postData = new FormData()

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }

      return await request.curl(context, "organisation_list_search", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationListSearch() and Exception:", err.message)
    }
  },
  /**
 * organisationFollowedList
 */
  async organisationFollowedList (context, filter = {}, currentPage = "") {
    try {
      let postData = new FormData()

      if (currentPage) {
        postData.append("currentPage", currentPage)
      }

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }
      return await request.curl(context, "organisation_list_followed", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationFollowedList() and Exception:", err.message)
    }
  },
  /**
 * myOrganisationList
 */
  async myOrganisationList (context, currentPage = "", filter = {}) {
    try {
      let postData = new FormData()

      if (currentPage) {
        postData.append("currentPage", currentPage)
      }

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }

      return await request.curl(context, "organisation_list_user_specific", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at myOrganisationList() and Exception:", err.message)
    }
  },
  /**
   * organisationView
   */
  async organisationView (context, organisationId, orgDomain = null) {
    try {
      let postData = new FormData()
      postData.append("org_id", organisationId)
      if (orgDomain) {
        postData.append("org_domain", orgDomain)
      }
      return await request.curl(context, "organisation_view", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationList() and Exception:", err.message)
    }
  },
  /**
   * organisationViewPublic
   */
  async organisationViewPublic (context, organisationId) {
    try {
      let postData = new FormData()
      postData.append("org_id", organisationId)
      return await request.curl(context, "organisation_public_view", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationList() and Exception:", err.message)
    }
  },
  /**
   * organisationAdd
   */
  async organisationAdd (context, organisationObj) {
    try {
      let postData = new FormData()

      for (let key in organisationObj) {
        if (organisationObj[key] || typeof organisationObj[key] == "number") {
          if (key === "org_currency") {
            if (organisationObj[key] && organisationObj[key]["currency_name"]) {
              postData.append(key, organisationObj[key]["currency_name"])
            }
          }
          else if (key === "org_deadline") {
            if (organisationObj[key]) {
              postData.append(key, moment(organisationObj[key]).format("YYYY-MM-DD"))
            }
          }
          else if (key == "deleted") {
            // Approved orgs should be published, hence setting deleted = 0
            postData.append(key, organisationObj[key])
          }
          else {
            if (organisationObj[key]) {
              postData.append(key, organisationObj[key])
            }
          }
        }
      }

      return await request.curl(context, "organisation_add", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationAdd() and Exception:", err.message)
    }
  },
  /**
   * organisationEdit
   */
  async organisationEdit (context, organisationObj) {
    try {
      let postData = new FormData()

      for (let key in organisationObj) {
        if (organisationObj["org_country"] && key === "org_country") {
          if (organisationObj[key]["country_name"]) {
            postData.append(key, organisationObj[key]["country_name"])
          }
        }
        else if (organisationObj["org_currency"] && key === "org_currency") {
          if (organisationObj[key]["currency_name"]) {
            postData.append(key, organisationObj[key]["currency_name"])
          }
        }
        else if (organisationObj["org_deadline"] && key === "org_deadline") {
          if (organisationObj[key]) {
            postData.append(key, moment(organisationObj[key]).format("YYYY-MM-DD"))
          }
        }
        else {
          if (organisationObj[key]) {
            postData.append(key, organisationObj[key])
          }
        }
      }

      return await request.curl(context, "organisation_edit", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationEdit() and Exception:", err.message)
    }
  },
  /**
   * organisationDelete
   */
  async organisationDelete (context, organisationId) {
    try {
      let postData = new FormData()

      postData.append("org_id", organisationId)

      return await request.curl(context, "organisation_delete", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationDelete() and Exception:", err.message)
    }
  },
  /**
   * organisation_publish_unpublish
   */
  async organisation_publish_unpublish (context, organisation) {
    try {
      let postData = new FormData()
      postData.append("org_id", organisation.org_id)
      postData.append("deleted", organisation.deleted)
      return await request.curl(context, "organisation_publish_unpublish", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationPublished() and Exception:", err.message)
    }
  },
  /**
   * organisation_List_Admin
   */
  async organisationListAdmin (context, filter = {}, currentPage = null) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()

      if (currentPage) {
        postData.append("currentPage", currentPage)
      }

      if (Object.keys(filter).length > 0) {
        postData.append("filter", JSON.stringify(filter))
      }

      return await request.curl(context, "organisation_list_admin", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationListAdmin() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * organisationCourseListAdm
   */
  async organisationCourseListAdm (context, orgId) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()
      postData.append("org_id", orgId)

      return await request.curl(context, "organisation_course_list_adm", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationListAdmin() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * organisation Edit Tnc
   */
  async organisationEditTnc (context, organisation) {
    try {
      let postData = new FormData()
      postData.append("org_id", organisation.org_id)
      postData.append("org_tnc", organisation.org_tnc)

      return await request.curl(context, "organisation_edit_tnc", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisationEditTnc() and Exception:", err.message)
    }
  },
  /**
   * organisation_reject_replace
   */
  async organisation_reject_replace (context, organisationTempObj) {
    try {
      let postData = new FormData()

      for (let key in organisationTempObj) {
        if (organisationTempObj[key]) {
          postData.append(key, organisationTempObj[key])
        }
      }
      return await request.curl(context, "organisation_reject_replace", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisation_reject_replace() and Exception:", err.message)
    }
  },
  /**
   * organisation_domain_validate_on_port
   */
  async organisation_domain_validate_on_port (context, domainValidateObj) {
    try {
      let postData = new FormData()

      for (let key in domainValidateObj) {
        if (domainValidateObj[key]) {
          postData.append(key, domainValidateObj[key])
        }
      }
      return await request.curl(context, "organisation_domain_validate_on_port", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisation_domain_validate_on_port() and Exception:", err.message)
    }
  },

  /**
   * organisation_domain_generate_sslcert
   */
  async organisation_domain_generate_sslcert (context, sslCertGenObj) {
    try {
      let postData = new FormData()

      for (let key in sslCertGenObj) {
        if (sslCertGenObj[key]) {
          postData.append(key, sslCertGenObj[key])
        }
      }

      return await request.curl(context, "ssl_cert_gen", postData, process.env.VUE_APP_SERVER_URL)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at organisation_domain_validate_on_port() and Exception:", err.message)
    }
  }
}

export {
  Organisations
}
